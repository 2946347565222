/* RejectedFund.css */
.container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  overflow-x: auto; /* Add horizontal scroll for small screens */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-break: break-word; /* Allow long content to break into multiple lines */
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

.approve-btn,
.reject-btn,
.save-btn {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  margin: 10px;
  width: 90%;
}

.reject-btn {
  background-color: #f44336;
}

.rejection-input {
  width: 100%;
  padding: 6px;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.checkbox-cell {
  display: flex;
  align-items: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 7px;
  background-color: rgb(242,242,242) !important;
  width:60px !important;
  height: 30px !important;
}

.checkbox-input {
  display: none;
}

.checkbox-text {
  margin-left: 5px;
}

.custom-checkbox {
  display:inline-block;
  position: realtive;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #ddd !important;
  border-radius: 3px;
 /*
  border: 1px solid #0c0c0c;
  display: inline-block;
  vertical-align: middle;
  position: relative;*/
}

.custom-checkbox::after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox.checked {
  background-color: #4caf50;
  border-color: #4caf50;
}

.custom-checkbox.checked::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 0;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.button-container {
  display: flex;
  justify-content: center !important;
      width: 50%;
      /*align-content: space-between;*/
      align-items:center ;
      margin:auto;
}

.action-btn {
  background-color: #4caf50;
  color: white;
  border-radius: 3%;
  padding:8px 16px ;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  font-weight:500;
  cursor: pointer;
  border:none;
  width: 50%;
}

.action-btn:last-child {
  background-color: #f44336;
}

.action-btn:hover {
  opacity: 0.8;
}
.rejection-reason-container{
      width: 50%;
      display: flex;
      flex-direction: column;
      position: absolute;
      background-color: #ddd;
      height: 300px;
      justify-content: center;
      margin-top: 20px;
      align-content: space-between;
      align-items:center ;
      align-self: center;
  border-radius: 10px;
  z-index: 1;
}
.rejection-reason-input{
  height: 100px;
  width: 90%;
  margin-bottom: 20px;
  border-radius: 10px;
}
td p{
  font-weight: 100;
  font-size: small;
}
.pgTitle {
  text-align: center;
  background-color: #fff;
  width: 100%;
  padding: 15px 0;
}
.card {
  border-radius: 15px;
  overflow: hidden;
}

.card-header {
  background-color: #007bff;
}

.table {
  margin-bottom: 0;
}

.table th {
  background-color: #343a40;
  color: white;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.tab-container {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  background-color: #f5f5f5;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

.tab.active {
  background-color: #153D77;
  color: white;
}

.tab-content {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 0 4px 4px 4px;
  background-color: #fff;
}

@media (max-width: 768px) {
  .tab-container {
    flex-direction: column;
  }

  .tab {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .tab.active {
    border-radius: 4px;
  } 

  .table-responsive {
    font-size: 0.9rem;
  }
  
  .btn-sm {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
  }
}

@media(max-width:480px) {
  .tab {
    padding: 8px 10px;
  }

  .tab-content {
    padding: 10px;
  }
}