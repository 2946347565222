.container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    overflow-x: auto; /* Add horizontal scroll for small screens */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-break: break-word; /* Allow long content to break into multiple lines */
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  
  .approve-btn,
  .reject-btn,
  .save-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    margin: 10px;
    width: 90%;
  }
  
  .reject-btn {
    background-color: #f44336;
  }
  
  .rejection-input {
    width: 100%;
    padding: 6px;
    margin-bottom: 8px;
    box-sizing: border-box;
  }
  
  .checkbox-cell {
    display: flex;
    align-items: center;
  }
  
  .checkbox-label {
    margin-left: 5px;
  }
  
  .checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .custom-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #0c0c0c;
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  
  .custom-checkbox::after {
    content: '';
    position: absolute;
    display: none;
  }
  
  .custom-checkbox.checked {
    background-color: #4caf50;
    border-color: #4caf50;
  }
  
  .custom-checkbox.checked::after {
    display: block;
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
        width: 50%;
        align-content: space-between;
        align-items:center ;
  }
  
  .action-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }
  
  .action-btn:last-child {
    background-color: #f44336;
  }
  
  .action-btn:hover {
    opacity: 0.8;
  }
  .rejection-reason-container{
        width: 50%;
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: #ddd;
        height: 300px;
        justify-content: center;
        margin-top: 20px;
        align-content: space-between;
        align-items:center ;
        align-self: center;
    border-radius: 10px;
    z-index: 1;
  }
  .rejection-reason-input{
    height: 100px;
    width: 90%;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  td p{
    font-weight: 100;
    font-size: small;
  }
  .pgTitle {
    text-align: center;
    background-color: #fff;
    width: 100%;
    padding: 15px 0;
  }